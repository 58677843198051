import React from "react"

import scssSettings from "../scss/_settings.scss"

/*

type color = string;
type scheme = "light-on-dark" | "dark-on-light";
type theme = {
  foreground: color,
  background: color,
  scheme,
  bodyClass: string
}

*/

export const zeusTheme = {
  foreground: scssSettings.midnight,
  background: scssSettings.cream,
  scheme: "dark-on-light",
  bodyClass: "zeus",
}

export const zeusDarkTheme = {
  foreground: scssSettings.cream,
  background: scssSettings.midnight,
  scheme: "dark-on-light",
  bodyClass: "zeus",
}

export const zeusAltTheme = {
  foreground: scssSettings.white,
  background: scssSettings.darkGreen,
  scheme: "light-on-dark",
  bodyClass: "zeus-alt",
}

export const athenaTheme = {
  foreground: scssSettings.black,
  background: scssSettings.white,
  scheme: "dark-on-light",
  bodyClass: "athena",
}

export const ThemeContext = React.createContext(getThemeBySlug())

export function getThemeBySlug() {
  let theme = zeusTheme
  const darkThemeDefaultPages = [
    "/contact/",
    "/services-and-expertise/",
    "/purpose-and-impact/",
    "/contact",
    "/services-and-expertise",
    "/purpose-and-impact",
  ]

  if (typeof window !== "undefined") {
    const extension = window.location.pathname
    if (darkThemeDefaultPages.includes(extension)) {
      theme = zeusDarkTheme
    }
  }

  return theme
}

export const ThemeProvider = ({ theme, children }) => {
  const darkThemeDefaultPages = [
    "/contact/",
    "/services-and-expertise/",
    "/purpose-and-impact/",
    "/contact",
    "/services-and-expertise",
    "/purpose-and-impact",
    "/contact/success",
    "/contact/success/",
  ]
  if (typeof window !== "undefined") {
    const extension = window.location.pathname
    if (darkThemeDefaultPages.includes(extension)) {
      theme = zeusDarkTheme
    }
  }

  React.useLayoutEffect(() => {
    let bodyClass = theme.bodyClass
    let root = document.documentElement
    root.style.setProperty("--theme-foreground", theme.foreground)
    root.style.setProperty("--theme-background", theme.background)
    root.style.setProperty("--header-position", theme.headerPosition)

    document.body.classList.add(bodyClass)
    return () => {
      document.body.classList.remove(bodyClass)
    }
  }, [theme])
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export default function useTheme() {
  return React.useContext(ThemeContext)
}
