import { useStaticQuery, Link, graphql } from "gatsby"
import React, { useState } from "react"

import LogoZeus from "./logo"
import LogoAthena from "./logoAthena"
import { useShyHeader } from "../hooks"
import useTheme from "../hooks/useTheme"
import Image from "./image"
import styles from "./header.module.scss"

const Header = ({ isEndlesss, mode, shyHeader, theme }) => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          issuesPage: entry(section: "athenaIssuesIndex") {
            title
            uri
          }
          issues: categories(
            group: "athenaIssues"
            orderBy: "dateCreated desc"
          ) {
            id
            title
            uri
            ... on Craft_athenaIssues_Category {
              id
              issueSubtitle
              coverImages {
                ... on Craft_coverImages_BlockType {
                  ratio_3x4 {
                    id
                    url
                    width
                    height
                    ... on Craft_zjContent_Asset {
                      altText
                    }
                  }
                  ratio_1x1 {
                    id
                    url
                    width
                    height
                    ... on Craft_zjContent_Asset {
                      altText
                    }
                  }
                }
              }
            }
          }
          athena: entry(section: "athena") {
            uri
            ... on Craft_athena_athena_Entry {
              featuredIssue {
                id
              }
            }
          }
          globalSet(handle: "mainNavigation") {
            ... on Craft_mainNavigation_GlobalSet {
              zeusNavigation {
                ... on Craft_zeusNavigation_BlockType {
                  id
                  navLink {
                    customText
                    text
                    type
                    url
                  }
                }
              }
              zeusSecondaryNavigation {
                ... on Craft_zeusSecondaryNavigation_BlockType {
                  id
                  navLink {
                    customText
                    text
                    type
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  theme = theme ? theme : useTheme()
  const hideHeader = useShyHeader()

  //
  // Hamburger menu
  //
  const menuStates = {
    open: {
      name: "open",
      classes: styles.isOpen,
      next: "closing",
      bodyOverflow: "hidden",
      buttonText: "Close Menu",
      burgerClass: styles.veggieburgerOpen,
    },
    opening: {
      name: "opening",
      // classes: `${styles.isOpen} ${styles.isOpening}`,
      classes: styles.isOpening,
      next: "open",
      bodyOverflow: "hidden",
      buttonText: "Close Menu",
      burgerClass: styles.veggieburgerOpen,
    },
    closed: {
      name: "closed",
      classes: styles.isClosed,
      next: "opening",
      bodyOverflow: "auto",
      buttonText: "Open Menu",
      burgerClass: styles.veggieburgerClosed,
    },
    closing: {
      name: "closing",
      // classes: `${styles.isClosed} ${styles.isClosing}`,
      classes: styles.isClosing,
      next: "closed",
      bodyOverflow: "hidden",
      buttonText: "Open Menu",
      burgerClass: styles.veggieburgerClosed,
    },
  }
  const initialState = menuStates.closed
  const [menu, setMenu] = useState(initialState)
  const updateMenu = () => {
    setMenu(menuStates[menu.next])
  }

  if (typeof document !== "undefined") {
    document.body.style.overflow = menu.bodyOverflow
  }

  // Trigger new menu state after 2 events happen
  // ::before and ::after pseudo elements both animate
  // TODO: Is there a generic "wait till all animations are done on/inside this element"?
  const [animationCount, setAnimationCount] = useState(0)

  const onAnimationEvent = () => {
    setAnimationCount(animationCount + 1)
    if (animationCount === 2) {
      updateMenu()
      setAnimationCount(0)
    }
  }

  // Navigation Content
  const zeusNavigationItems = data.craft.globalSet.zeusNavigation
  const zeusSecondaryNavigationItems =
    data.craft.globalSet.zeusSecondaryNavigation
  const issuesPage = data.craft.issuesPage
  const athenaIssues = data.craft.issues

  const athena = data.craft.athena
  // Show the current issue when in "zeus" mode
  const featuredIssueId = data.craft.athena.featuredIssue[0].id
  const featuredIssue = athenaIssues.find(issue => issue.id === featuredIssueId)

  // Used in the .athena-animate and .zeus-navigate classes to set numbered
  // classes for CSS transition delays
  let athenaAnimationCount = 1
  let zeusAnimationCount = 1
  const getAnimateClasses = mode => {
    let classes = null
    if (mode === "zeus") {
      classes = `${styles.animate} ${styles[`animate${zeusAnimationCount}`]}`
      zeusAnimationCount++
    } else if (mode === "athena") {
      classes = `${styles.animate} ${styles.athenaMenu} ${
        styles[`animate${athenaAnimationCount}`]
      }`
      athenaAnimationCount++
    } else {
      console.warn("No mode sent for animation class.")
    }
    return classes
  }

  return (
    <>
      <div
        className={`${styles.headerWrapper} ${styles[`${mode}`]} ${
          hideHeader && shyHeader ? styles.headerHidden : ""
        } ${shyHeader ? styles.isFixed : ""}`}
      >
        <header
          className={`${styles.header} ${
            isEndlesss ? styles.endlesssHeader : ""
          }`}
        >
          <div className={styles.logo}>
            {mode === "zeus" ? (
              <Link to="/">
                <LogoZeus id="zeus-header-logo" color={theme.foreground} />
              </Link>
            ) : mode === "athena" ? (
              <Link to={`/${data.craft.athena.uri}`}>
                <LogoAthena id="athena-header-logo" color={theme.foreground} />
              </Link>
            ) : null}
          </div>
          <div
            className={`${styles.veggieburger} ${
              mode === "athena" ? styles.athenaButton : null
            } ${menu.burgerClass}`}
          >
            {/*
            A11Y menu toggle
            https://www.a11ymatters.com/pattern/mobile-nav/
          */}

            <button
              onClick={updateMenu}
              aria-expanded="false"
              aria-controls="menu"
              // Disable the button on opening/closing active states
              disabled={
                menu.name !== menuStates.open.name &&
                menu.name !== menuStates.closed.name
              }
            >
              <i
                className={`${styles.veggieburgerLine} ${styles.veggieburgerLineTop}`}
                aria-hidden={true}
              ></i>
              <i
                className={`${styles.veggieburgerLine} ${styles.veggieburgerLineMid}`}
                aria-hidden={true}
              ></i>
              <i
                className={`${styles.veggieburgerLine} ${styles.veggieburgerLineBottom}`}
                aria-hidden={true}
              ></i>
              <span className="screen-reader-only">{menu.buttonText}</span>
            </button>
          </div>
          <nav
            // https://www.w3.org/WAI/tutorials/menus/structure/
            aria-labelledby="mainmenulabel"
            id="menu"
            onAnimationStart={onAnimationEvent}
            onAnimationEnd={onAnimationEvent}
            className={`
              ${styles.overlay}
              ${menu.classes}
              `}
          >
            <i className={styles.before} aria-hidden={true}></i>
            <i className={styles.after} aria-hidden={true}></i>
            <h2 id="mainmenulabel" className="screen-reader-only">
              Main Menu
            </h2>
            <div className={styles.overlayInner}>
              {/* Zeus */}
              <div className={`${styles.overlayNav} ${styles.overlayNavZeus}`}>
                <div>
                  <h3 className="screen-reader-only">
                    Zeus Jones Main Navigation
                  </h3>
                  <div className={styles.overlayLogo}>
                    <Link to="/">
                      <LogoZeus id="zeus-overlay-logo" />
                    </Link>
                  </div>
                  <ul>
                    {zeusNavigationItems.map((item, i) => (
                      <li
                        key={`zeus-nav-${item.id}`}
                        className={`${styles.zeusNavItem} ${
                          i + 1 === zeusNavigationItems.length ? "mb-2" : ""
                        }`}
                      >
                        <div className={getAnimateClasses("zeus")}>
                          <NavLink
                            link={item.navLink}
                            className={styles.zeusNavItemLink}
                            hoverClone={true}
                          />
                        </div>
                      </li>
                    ))}
                    {zeusSecondaryNavigationItems.map(item => (
                      <li
                        key={`zeus-nav-${item.id}`}
                        className={`${styles.zeusSecondaryNavItem}`}
                      >
                        <div className={getAnimateClasses("zeus")}>
                          <NavLink link={item.navLink} hoverClone={false} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* Athena */}
              <div
                className={`${styles.overlayNav} ${styles.overlayNavAthena}`}
              >
                <h3 className="screen-reader-only">Athena Jones Navigation</h3>
                <div>
                  <div className={styles.overlayLogo}>
                    <Link to={`/${data.craft.athena.uri}`}>
                      <LogoAthena id="athena-overlay-logo" color="#FCFAF3" />
                    </Link>
                  </div>
                  {mode === "zeus" ? (
                    <div>
                      <div
                        className={`${
                          styles.athenaFeatureImage
                        } ${getAnimateClasses("athena")} mb-4`}
                      >
                        <Link to={`/${data.craft.athena.uri}`}>
                          <Image
                            height={
                              featuredIssue.coverImages[0].ratio_1x1[0].height
                            }
                            width={
                              featuredIssue.coverImages[0].ratio_1x1[0].width
                            }
                            sizes="336px"
                            url={featuredIssue.coverImages[0].ratio_1x1[0].url}
                            altText={
                              featuredIssue.coverImages[0].ratio_1x1[0].altText
                            }
                          />
                        </Link>
                      </div>
                      <p
                        className={`${
                          styles.athenaFeatureDescription
                        } ${getAnimateClasses("athena")} mono`}
                      >
                        Athena is a playground for uncommon wisdom. Created by
                        Zeus Jones.
                      </p>
                      <div className={`${getAnimateClasses("athena")}`}>
                        <Link
                          className="button button--theme-dark button--small"
                          to={`/${athena.uri}`}
                        >
                          Explore our thinking
                        </Link>
                      </div>
                    </div>
                  ) : mode === "athena" ? (
                    <div>
                      <ol className={styles.athenaIssueList}>
                        {athenaIssues.map((issue, i) => {
                          let count = athenaIssues.length - i

                          return (
                            <li
                              className={styles.listItem}
                              key={`athena-nav-${issue.id}`}
                            >
                              <Link
                                className={styles.issueLink}
                                to={`/${issue.uri}`}
                              >
                                <div
                                  className={`mono ${getAnimateClasses(
                                    "athena"
                                  )}`}
                                  aria-hidden={true}
                                >
                                  Issue {count.toString().padStart(2, "0")}
                                </div>
                                <div
                                  className={`${
                                    styles.athenaIssueTitle
                                  } ${getAnimateClasses("athena")}`}
                                >
                                  {issue.title}
                                </div>
                              </Link>
                            </li>
                          )
                        })}
                        <li
                          className={`${
                            styles.athenaIssuesLink
                          } ${getAnimateClasses("athena")}`}
                        >
                          <Link to={`/${issuesPage.uri}`}>
                            {issuesPage.title}
                          </Link>
                        </li>
                      </ol>

                      <div
                        className={`${
                          styles.athenaDescription
                        } ${getAnimateClasses("athena")}`}
                      >
                        <p className="mono">
                          Athena is a playground for uncommon wisdom. Created by
                          Zeus Jones.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  )
}

function NavLink(props) {
  // URL api not available on the server
  if (typeof window === "undefined") {
    return null
  }
  const { link, hoverClone, ...otherProps } = props
  const linkCustomText = link.customText
  const linkText = link.text
  const linkType = link.type
  const linkUrl = link.url
  const url = new URL(linkUrl)
  const isExternal = linkType === "url"
  const text = linkCustomText ? linkCustomText : linkText

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? {
          className: `${otherProps.className} ${styles.navLinkActive}`,
        }
      : null
  }

  if (isExternal) {
    return (
      <a
        {...otherProps}
        target="_blank"
        rel="noopener noreferrer"
        href={linkUrl}
      >
        <span className={styles.navLinkText}>{text}</span>
        {hoverClone && (
          <span className={styles.navLinkClone} aria-hidden={true}>
            {text}
          </span>
        )}
      </a>
    )
  } else {
    return (
      <Link
        {...otherProps}
        getProps={url.pathname === "/" ? undefined : isPartiallyActive}
        to={url.pathname}
      >
        <span className={styles.navLinkText}>{text}</span>
        {hoverClone && (
          <span className={styles.navLinkClone} aria-hidden={true}>
            {text}
          </span>
        )}
      </Link>
    )
  }
}

export default Header
